import React from "react";
import Router from './components/Router'

import "./assets/scss/main.scss";
import GlobalState from "./context/GlobalState";
import CookieConset from "./components/common/CookieConset";
import LegalAge from "./components/common/LegalAge";
import Header from "./components/common/Header";

const App = () => {

  return (
    <div className='app'>
      <Header />
      <GlobalState>
        <Router />
      </GlobalState>
      <LegalAge />
      <CookieConset />
      <div className='overlay' />
    </div>
  );
};

export default App;