import React from "react";
import { ReactComponent as EstrellaSVG } from "../../assets/images/logo-estrella.svg";

function Header(){

	return (
    <header className='header'>
        <EstrellaSVG />
    </header>
  );



}

export default Header;