import React from 'react'
import PropTypes from 'prop-types'
import ReactGA from 'react-ga'
import {config} from '../../config/app'
import isProduction from '../../utils/isProduction'
import cnConsole from '../../utils/cnConsole'
import { withRouter } from 'react-router-dom'

 if (isProduction()) {
   ReactGA.initialize(config.googleAnalitycsId);
 } else {
   ReactGA.initialize(config.googleAnalitycsId, { testMode: true });
 }

class GAListener extends React.Component {

    static contextTypes = {
        router: PropTypes.object
    }

    componentDidMount() {
        cnConsole("!GAListener")
        this.sendPageView(this.props.history.location)
        this.props.history.listen(this.sendPageView)
    }

    sendPageView(location) {
        cnConsole("! sendPageView: ", location.pathname)
        ReactGA.set({ page: location.pathname })
        ReactGA.pageview(location.pathname)
    }

    render() {
        return this.props.children
    }
}

export default withRouter(GAListener)