import {createContext} from 'react'

export default createContext({
  isLoading: false,
  email: null,
  idSpotify: null,
  genres: null,
  festival: null,
  setIdSpotify: idSpotify => {},
  setEmail: email => {},
  setGenres: genders => {},
  setFestival: festival => {},
  setLoading: isLoading => {}
});