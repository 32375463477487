import React, { useEffect } from "react";
import Cookies from "js-cookie";
import logo from "../../assets/images/logo_estrella.png";

const LegalAge = () => {

  const checkLegal = () => {
    const hideCookies = () =>
      (document.querySelector(".modal__legal").style.display = "none");
    document
      .querySelector(".modal__legal-link--si")
      .addEventListener("click", () => {
        Cookies.set("age-cookies", "acepted", { expires: 364 });
        hideCookies();
      });
    if (Cookies.get("age-cookies")) {
      hideCookies();
    }
  };

  useEffect(checkLegal, []);

  return (
    <div className="modal__legal">
      <div className="modal__legal-container">
        <div className="modal__legal-image">
          <img src={logo} alt="Estrella Galicia" />
        </div>
        <div className="modal__legal-bienvenido">Bienvenido</div>
        <div className="modal__legal-mayor">¿Eres mayor de edad?</div>
        <ul className="modal__legal-options">
          <li className="modal__legal-link modal__legal-link--si si">
            <span title="si">
              Si
            </span>
          </li>
          <li className="modal__legal-link no">
            <a href="http://google.com" title="No">
              No
            </a>
          </li>
        </ul>
        <div className="modal__legal-nota">Confirma si tienes 18 años</div>
      </div>
    </div>
  );
};

export default LegalAge;