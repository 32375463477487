import React, { useState, useContext, useEffect } from 'react'
import setAuthTokenInAxios from '../services/setAuthTokenInAxios'
import { spotifyService } from '../services/api'
import EmailModal from '../components/email/EmailModal'

import spotify from '../assets/images/spotify.png'
import spotify2x from '../assets/images/spotify@2x.png'
import spotify3x from '../assets/images/spotify@3x.png'
import appContext from '../context/appContext'
import { isValidEmail } from '../utils/validation'

const LoginPage = props => {
  const [error, setError] = useState(false)
  const [isModalShown, setModalShown] = useState(false)
  const [email, setEmail] = useState('')
  const [isAcepted, setAcepted] = useState(false)
  const [errorEmail, setErrorEmail] = useState('')
  const [errorAcepted, setErrorAcepted] = useState('')

  const context = useContext(appContext)

  function saveEmailAndExit (email) {
    context.setEmail(email)
    props.history.replace('/thinking')
  }

  async function checkGenres () {
    try {
      const lastGenres = await spotifyService.getUserLastPlayedGenres()
      context.setGenres(lastGenres)
      await checkUserSpotify()
    } catch (error) {
      setError(error.message)
    }
  }

  const successSpotifyLogin = async token => {
    setAuthTokenInAxios(token)
    await checkGenres()
  }

  async function checkUserSpotify () {
    try {
      const user = await spotifyService.getUser()
      context.setIdSpotify(user.id)
      if (!user.email) {
        return toggleModalShown()
      }
      saveEmailAndExit(user.email)
    } catch (error) {
      setError(error.message)
    }
  }

  useEffect(
    () => {
      if (props.match.params.token) {
        successSpotifyLogin(props.match.params.token)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.match.params.token]
  )

  function toggleModalShown () {
    setModalShown(!isModalShown)
  }

  function onSubmitEmailForm (event) {
    event.preventDefault()
    setErrorEmail('')
    setErrorAcepted('')
    if (!isValidEmail(email)) {
      setErrorEmail('Escribe un email válido')
    }
    if (!isAcepted) {
      setErrorAcepted('Debes aceptar la bases y las políticas de privacidad')
    }
    if (isValidEmail(email) && isAcepted) {
      saveEmailAndExit(email)
    }
  }

  return (
    <main className='result'>
      {isModalShown ? (
        <EmailModal
          email={email}
          setEmail={setEmail}
          errorEmail={errorEmail}
          isAcepted={isAcepted}
          setAcepted={setAcepted}
          errorAcepted={errorAcepted}
          onSubmit={onSubmitEmailForm}
          onClickClose={toggleModalShown}
        />
      ) : null}
      {error ? <div className='errors'>{error}</div> : null}
      <div className='result__icon'>
        <img
          src={spotify}
          srcSet={`
            ${spotify} 1x,
            ${spotify2x} 2x,
            ${spotify3x} 3x
          `}
          alt='spotify'
        />
      </div>
      <a
        className='boton-general'
        href='https://www.estrellafy.com/spotify/login'
      >
        login
      </a>
    </main>
  );
}

export default LoginPage
