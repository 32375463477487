import axios from "axios";

const addTokenToAxiosConfig = token => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
}
  

const removeTokenFromConfig = () => {
  delete axios.defaults.headers.common["Authorization"];
}

export default token => {
  if (token) {
    return addTokenToAxiosConfig(token)
  } 
  removeTokenFromConfig()
}

