import React, {useState} from "react";
import appContext from './appContext'
import isProduction from '../utils/isProduction'
import data from "../data/festivalsGenres.json";

const GlobalState = props => {
  const [isLoading, setLoading] = useState(false)
  const [email, setEmail] = useState(false)
  const [genres, setGenres] = useState(false);
  const [festival, setFestival] = useState(isProduction() ? null : data.festivales[1]);
  const [idSpotify, setIdSpotify] = useState(null);


  return (
    <appContext.Provider
      value={{
        isLoading,
        email,
        genres,
        festival,
        idSpotify,
        setLoading,
        setEmail,
        setGenres,
        setFestival,
        setIdSpotify
      }}
    >
      {props.children}
    </appContext.Provider>
  );

}

export default GlobalState;