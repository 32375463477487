import { TransitionGroup, CSSTransition } from "react-transition-group";
import React from 'react';
import GAListener from "./analytics/GAListener";
import { HashRouter , Switch, Route } from "react-router-dom";
import HomePage from "../pages/HomePage";
import ThinkingPage from "../pages/ThinkingPage";
import LoginPage from "../pages/LoginPage";
import ResultadoPage from "../pages/ResultadoPage";

const Router = () => {
  return (
    <>
      <HashRouter>
        <Route
          render={({ location }) => (
            <GAListener>
              <TransitionGroup>
                <CSSTransition
                  key={location.key}
                  timeout={0}
                  classNames={"fade"}
                >
                  <Switch location={location}>
                    <Route exact path='/' component={HomePage} />
                    <Route exact path='/login' component={LoginPage} />
                    <Route
                      path='/login-callback/:id/:token'
                      component={LoginPage}
                    />
                    <Route
                      exact
                      path='/thinking'
                      component={ThinkingPage}
                    />
                    <Route exact path='/result' component={ResultadoPage} />
                  </Switch>
                </CSSTransition>
              </TransitionGroup>
            </GAListener>
          )}
        />
      </HashRouter>
    </>
  );
};

export default Router;