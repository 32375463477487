import React, {useEffect}  from 'react';
import Cookies from "js-cookie";

const CookieConset = () => {

  const checkCookies = () => {
    const hideCookies = () =>
      (document.querySelector(".cookies-consent").style.display = "none");
    document
      .querySelector(".cookies-consent__link")
      .addEventListener("click", () => {
        Cookies.set("accept-cookies", "acepted", { expires: 364 });
        hideCookies();
      });
    if (Cookies.get("accept-cookies")) {
      hideCookies();
    }
  };

  useEffect(checkCookies, []);

  return (
    <div className="cookies-consent">
      <div className="cookies-consent__contenido">
         Utilizamos cookies propias y de terceros para ofrecer nuestros
        servicios, ofrecer una mejor experiencia de navegación y mostrar
        publicidad personalizada. Si continúa navegando acepta su instalación
        y uso. Puede cambiar la configuración según se indica en nuestra{" "}
        <a
          href="https://estrellagalicia.es/politica-de-privacidad-y-cookies/"
          target="_blank"
          rel="noopener noreferrer"
          title="política de cookies"
          className="class"
        >
          {" "}
          política de cookies.
        </a>
      </div>
      <button className="cookies-consent__link">Aceptar</button>
    </div>
  );
};

export default CookieConset;