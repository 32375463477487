import React, { useEffect, useContext } from "react";
import appContext from '../context/appContext';
import SocialMenu from "../components/social/SocialMenu";

const ResultadoPage = props => {

  const context = useContext(appContext);

  const text = context.festival ? context.festival.text : ''
  useEffect(() => {
    if (!context.email || !context.festival) {
      return props.history.push("/");
    }
  });

  return (
    <main className='result'>
      <h2 className='result__title title'>
        el FESTIVAL que va más contigo es
      </h2>
      <div className='result__icon'>
        {context.festival ? (
          <a
            href={context.festival.url}
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={`images/${context.festival.image}.png`} alt='' />
          </a>
        ) : null}
      </div>
      <p
        className='result__note text'
        dangerouslySetInnerHTML={{ __html: text }}
      />
      <div>
        <a
          href={context.festival.url}
          target='_blank'
          rel='noopener noreferrer'
          className='boton-general'
        >
          DESCUBRE MÁS
        </a>
      </div>
      <SocialMenu />
    </main>
  );
};

export default ResultadoPage;