import axios from "axios";
import { flatten, uniq } from "ramda";
import isProduction from '../utils/isProduction'
import {config} from '../config/app'

export const spotifyService = {
  getUser,
  getUserLastPlayedGenres,
  getGenres
};

async function getUser() {
  return axios.get("https://api.spotify.com/v1/me").then(response => {
    return response.data;
  }).catch(err => {
    throw new Error(err)
  })
}

async function getUserLastPlayedGenres() {
  const response = await axios.get("https://api.spotify.com/v1/me/player/recently-played")
  return await getArtistsGenres(response.data.items)
}

async function getArtistsGenres(artists) {
  const arrayGenres = await Promise.all(artists.map(async artist => {
    const genres = await Promise.all(artist.track.artists.map(loopArtistsToFindGenres))
    return genres;
  }));
  return flatten(uniq(arrayGenres)).toString();
}

async function loopArtistsToFindGenres(artist) {
   const genres = await findGenresInArtist(artist.id);
   return genres
}

async function findGenresInArtist(artistId) {
  const response = await axios.get(
    `https://api.spotify.com/v1/artists/${artistId}`
  );
  if (response.data.genres.length) {
    return response.data.genres
  }
  return []
}

async function getGenres() {
  axios
    .get("https://api.spotify.com/v1/recommendations/available-genre-seeds")
    .then(response => {
      console.log(response);
    });
}

export const saveuser  = async function(id, email, festivaName) {
  const url = isProduction()
    ? config.url.prod.save
    : config.url.dev.save;
  const response = await axios.post(url, {
    id: id,
    email: email,
    result: festivaName
  })

  return response
}




