import isProduction from "../utils/isProduction";

export const config = {
         isActive: true,
         spotiId: isProduction()
           ? "2ba54db17fe9462e9fe789901b8e3d5d"
           : "2ba54db17fe9462e9fe789901b8e3d5d",
         appURL: "https://www.estrellafy.com",
         googleAnalitycsId: "UA-115441753-5",
         url: {
           prod: {
             base: "https://www.estrellafy.com",
             redirect: "https://www.estrellafy.com/callback",
             save: "https://www.estrellafy.com/api/user"
           },
           dev: {
             base: "https://www.estrellafy.com",
             redirect: "https://www.estrellafy.com/callback",
             save: "https://www.estrellafy.com/api/user"
           }
         }
       };
