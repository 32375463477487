import React, { useEffect, useContext } from "react";

import spinner from "../assets/images/spinner.png";
import spinner2x from "../assets/images/spinner@2x.png";
import spinner3x from "../assets/images/spinner@3x.png";
import findFestival from '../data/findFestival';
import appContext from '../context/appContext';
import { saveuser } from "../services/api";

const ThinkingPage = props => {
  const context = useContext(appContext);

  useEffect(() => {
    if (!context.email) {
      return props.history.push("/");
    }
  });

  useEffect(
    () => {
      const festival = findFestival(context.genres);
      context.setFestival(festival);
      saveUser(festival);
      whenTimePassGoResult(3000);
    },
    // eslint-disable-next-line
    []
  );
  async function saveUser(festival) {
    await saveuser(context.idSpotify, context.email, festival.name);
  }

  const whenTimePassGoResult = time => {
    setTimeout(() => {
      props.history.push("/result");
    }, time);
  };

  return (
    <main className='thinking'>
      <h2 className='thinking__title title'>
        analizando tus gustos musicales...
      </h2>
      <div className='thinking__icon'>
        <div className='thinking__wheel'>
          <img
            src={spinner}
            srcSet={`
              ${spinner} 1x,
              ${spinner2x} 2x,
              ${spinner3x} 3x
            `}
            alt='spinner'
          />
        </div>
        <div className='thinking__brain' />
      </div>
      <p className='thinking__note text'>
        ... Detectando el FESTIVAL que
        <span> más encaja contigo</span>
      </p>
    </main>
  );
};

export default ThinkingPage;