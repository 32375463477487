import React from 'react';

const Error = ({text}) => {
  return (
    <>
      { text 
        ? (<div className="error">{text}</div>)
        :null
      }
    </>
  )
};

export default Error;