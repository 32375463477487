import React, { useState, useEffect, useContext } from "react";
import ReactGA from "react-ga";
import { config } from "../../config/app";
import appContext from "../../context/appContext";

import facebook from '../../assets/images/facebook.png'
import twitter from '../../assets/images/twitter.png'
import whatsapp from '../../assets/images/whatsapp.png'

const SocialMenu = () => {

  const context = useContext(appContext);

  const [whatsAppURL, setWhatsppURL] = useState("");
  const [facebookURL, setFacebookURL] = useState("");
  const [twitterURL, setTwitterURL] = useState("");

  const createWhatsAppURL = () => {
    let siteURL = `${config.appURL}?share=${context.festival.image}&wh=1`;
    let url = `whatsapp://send?text=${encodeURIComponent(siteURL)}`;
    setWhatsppURL(url);
  };
  useEffect(createWhatsAppURL, []);

  const createFacebookURL = () => {
    let siteURL = `https://www.facebook.com/sharer.php?u=${
      config.appURL
    }?share=${context.festival.image}`;
    setFacebookURL(siteURL);
  };
  useEffect(createFacebookURL, []);

  const createTwitterURL = () => {
    let siteURL = `${config.appURL}?share=${context.festival.image}`;
    let text = `Dime qué escuchas y te diré cuál es tu festival del verano. Participa y consigue un pack de cervezas Estrella Galicia para un mes. ${siteURL}`;
    setTwitterURL(
      `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}`
    );
  };
  useEffect(createTwitterURL, []);

  return (
    <ul className='social-menu'>
      <li className='item'>
        <a
          onClick={() => {
            ReactGA.event({
              category: "Share",
              action: "share facebook"
            });
          }}
          href={facebookURL}
          target='_blank'
          rel='noopener noreferrer'
        >
          <img src={facebook} alt='facebook' />
        </a>
      </li>
      <li className='item'>
        <a
          onClick={() => {
            ReactGA.event({
              category: "Share",
              action: "share twitter"
            });
          }}
          href={twitterURL}
          target='_blank'
          rel='noopener noreferrer'
        >
          <img src={twitter} alt='twitter' />
        </a>
      </li>
      <li className='item'>
        <a
          onClick={() => {
            ReactGA.event({
              category: "Share",
              action: "share whatsapp"
            });
          }}
          href={whatsAppURL}
          target='_blank'
          rel='noopener noreferrer'
        >
          <img src={whatsapp} alt='whatsapp' />
        </a>
      </li>
    </ul>
  );
};

export default SocialMenu;