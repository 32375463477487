import React  from "react";
import Error from "../common/Error";

function EmailModal (props){
	return (
    <div className='email-modal'>
      <button
        onClick={props.onClickClose}
        className='close'
        aria-label='button'
      >
        X
      </button>
      <div className='text'>PARA PODER CONTINUAR NECESITAMOS TU EMAIL</div>
      <div className='separacion' />
      <form onSubmit={props.onSubmit}>
        <input
          type='text'
          value={props.email}
          onChange={event => {
            props.setEmail(event.target.value);
          }}
        />
        <Error text={props.errorEmail} />
        <label htmlFor='check'>
          <input
            checked={props.isAcepted}
            onChange={event => {
              const target = event.target;
              const value =
                target.type === "checkbox" ? target.checked : target.value;
              props.setAcepted(value);
            }}
            type='checkbox'
            id='check'
          />
          He leÍdo y acepto las{" "}
          <a href='http:temp.com' target='_blank' rel='noopener noreferrer'>
            bases legales
          </a>{" "}
          y la{" "}
          <a href='http:temp.com' target='_blank' rel='noopener noreferrer'>
            política de privacidad
          </a>
        </label>
        <Error text={props.errorAcepted} />
        <button className='boton'>ENVIAR</button>
      </form>
    </div>
  );



}

export default EmailModal;