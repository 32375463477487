import React, {useEffect} from 'react'
import imagenEstrella from "../assets/images/estrellify.png";
import {Link} from 'react-router-dom'

const HomePage = () => {

  const scrollToTop = () => {
  }
  useEffect(scrollToTop, [])

  return (
    <main className='home'>
      <div>
        <img
          className='imagen-estrella'
          src={imagenEstrella}
          alt='Imagen Estrellafy'
        />
        <div className='text-home text'>
          DIME QUÉ ESCUCHAS Y TE DIRÉ <span>CUÁL ES TU FESTIVAL</span>
        </div>
        <div className='text-home text text--small'>
          PARTICIPA Y CONSIGUE{" "}
          <span>UN PACK DE ESTRELLA GALICIA PARA UN MES</span>
        </div>
      </div>
      <div>
        <Link to='/login' className='boton-general'>
          COMENZAR
        </Link>
      </div>
      <div className='text text--legal'>
        Colsulta las{" "}
        <a
          href='https://terms.easypromosapp.com/t/17059'
          target='_blank'
          rel='noopener noreferrer'
        >
          {" "}
          Bases Legales
        </a>
      </div>
    </main>
  );
};

export default HomePage;