import data from  "./festivalsGenres.json";

export default (userGenres) => {
  const matches =  data.festivales.map(festivalData =>
    countGenresMatch(festivalData.genres, userGenres)
    );
    return data.festivales[matches.indexOf(Math.max(...matches))]; 
  }
  
function countGenresMatch (fenrivalGenres, userGenres) {
  return fenrivalGenres.reduce((prevGenreCount, genre) => {
    const expresion = new RegExp(genre, "g");
    const matches = userGenres.match(expresion);
    const countMatches = (matches || []).length;
    return prevGenreCount + countMatches;
  }, 0);
}